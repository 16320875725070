import { SelectableOption } from '@monorepo/shared/types/SelectableOption';
import { getNonArchivedSelectableOptions } from '@monorepo/shared/utils/getNonArchivedSelectableOptions';
import { useMemo } from 'react';
import { useProjectUsers } from './useProjectUsers';

type UseProjectUserOptions = (args: {
  projectId?: string;
  previouslySavedUserId?: string;
}) => {
  userOptions?: SelectableOption[];
} & Omit<ReturnType<typeof useProjectUsers>, 'users'>;

export const useProjectUserOptions: UseProjectUserOptions = ({
  projectId,
  previouslySavedUserId,
}) => {
  const { users, ...queryInfo } = useProjectUsers({
    projectId,
    includeArchived: true,
  });

  // include all active users as options, and an archived user if they were the original selection for this component
  const userOptions = useMemo(() => {
    const optionsWithArchived =
      users &&
      users.map((u) => ({
        value: u.userId,
        label: u.userName,
        isArchived: !!u.is_archived,
      }));
    return getNonArchivedSelectableOptions(
      optionsWithArchived,
      previouslySavedUserId,
    );
  }, [users, previouslySavedUserId]);

  return { userOptions, ...queryInfo };
};
