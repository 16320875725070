import { SelectableOption } from '@monorepo/shared/types/SelectableOption';

type SelectableOptionWithArchived = SelectableOption & {
  isArchived: boolean;
};

// Include archived option only if it was originally selected;
export function getNonArchivedSelectableOptions(
  optionsWithArchived?: SelectableOptionWithArchived[],
  prevSelectedValue?: string,
  nonExistingOptionLabel = 'Selected option no longer exits',
): SelectableOption[] {
  if (!optionsWithArchived) return [];

  const filtered = optionsWithArchived
    .filter((o) => o.value === prevSelectedValue || !o.isArchived)
    .sort((o1, o2) => o1.label.localeCompare(o2.label));
  if (!prevSelectedValue) {
    return filtered;
  }

  // if previously selected option doesn't exist anymore – show a placeholder label.
  const prevSelectedOption = optionsWithArchived.find(
    (o) => o.value === prevSelectedValue,
  );
  return prevSelectedOption
    ? filtered
    : [
        ...filtered,
        {
          label: `[${nonExistingOptionLabel}]`,
          value: prevSelectedValue,
        },
      ];
}
