import FlatfileListener from '@flatfile/listener';
import { autocast } from '@flatfile/plugin-autocast';
import { recordHook } from '@flatfile/plugin-record-hook';
import { validateAndSubmitData } from '../helpers/flatfileDataSubmissionHelpers';
import {
  FlatfileLoggingContext,
  RecordHookCallback,
  SubmitDataCallback,
  SubmitErrorCallback,
  SUBMIT_ACTION_NAME,
} from '../types/flatfileTypes';

/**
 * cast e.g. boolean-column values like 'y' and 'n' to booleans
 */
function addAutocasting(listener: FlatfileListener, sheetSlug: string) {
  listener.use(autocast(sheetSlug));
}

function addRecordHook(
  listener: FlatfileListener,
  sheetSlug: string,
  recordHookCallback?: RecordHookCallback,
) {
  if (recordHookCallback) {
    listener.use(recordHook(sheetSlug, recordHookCallback));
  }
}

function handleSubmit(
  listener: FlatfileListener,
  onSubmitData: SubmitDataCallback,
  loggingContext: FlatfileLoggingContext,
  onSubmitError?: SubmitErrorCallback,
  customErrorMessage?: string,
) {
  listener.on(
    'job:ready',
    { job: `workbook:${SUBMIT_ACTION_NAME}` },
    async (flatfileEvent) => {
      const {
        context: { jobId, workbookId },
      } = flatfileEvent;

      await validateAndSubmitData(
        { jobId, workbookId },
        onSubmitData,
        loggingContext,
        onSubmitError,
        customErrorMessage,
      );
    },
  );
}

export const createFlatfileListener = (
  sheetSlug: string,
  onSubmitData: SubmitDataCallback,
  loggingContext: FlatfileLoggingContext,
  {
    recordHookCallback,
    onSubmitError,
    customErrorMessage,
  }: {
    recordHookCallback?: RecordHookCallback;
    onSubmitError?: SubmitErrorCallback;
    customErrorMessage?: string;
  },
) => {
  const flatfileListener = FlatfileListener.create((listener) => {
    // If plugins are listening to the same Flatfile event,
    // their handler will be resolved in order in which those listener were added
    addAutocasting(listener, sheetSlug);
    addRecordHook(listener, sheetSlug, recordHookCallback);
    handleSubmit(
      listener,
      onSubmitData,
      loggingContext,
      onSubmitError,
      customErrorMessage,
    );
  });

  return flatfileListener;
};
