import { SaveWorkflowStepRequest, WorkflowStepResponse } from 'mapistry-shared';
import http from '../httpClient';

export type CreateWorkflowStepParams = {
  organizationId: string;
  workflowId: string;
  step: SaveWorkflowStepRequest;
};

export type CreateWorkflowStepResponse = WorkflowStepResponse;
export type UpdateWorkflowStepResponse = WorkflowStepResponse;

export async function createWorkflowStep({
  organizationId,
  workflowId,
  step,
}: CreateWorkflowStepParams) {
  const res = await http.post<CreateWorkflowStepResponse>(
    `/api/v2/organizations/${organizationId}/workflows/${workflowId}/steps`,
    step,
  );
  return res.data;
}

export type UpdateWorkflowStepParams = CreateWorkflowStepParams & {
  stepId: string;
};

export async function updateWorkflowStep({
  organizationId,
  workflowId,
  step,
  stepId,
}: UpdateWorkflowStepParams) {
  const res = await http.put<UpdateWorkflowStepResponse>(
    `/api/v2/organizations/${organizationId}/workflows/${workflowId}/steps/${stepId}`,
    step,
  );
  return res.data;
}

export type DeleteWorkflowStepParams = {
  organizationId: string;
  workflowId: string;
  stepId: string;
};

export async function deleteWorkflowStep({
  organizationId,
  workflowId,
  stepId,
}: DeleteWorkflowStepParams) {
  const res = await http.delete<void>(
    `/api/v2/organizations/${organizationId}/workflows/${workflowId}/steps/${stepId}`,
  );
  return res.data;
}
