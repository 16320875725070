import {
  QueryColumnOptionResponse,
  QueryRequest,
  QueryResponse,
} from 'mapistry-shared';
import qs from 'qs';
import http from '../httpClient';

export interface FetchQueryResultsParams {
  originId: string;
  organizationId: string;
  perPage?: number;
  projectId: string;
  query: QueryRequest;
  requestedPage?: number;
}

export type FetchQueryResultsResponse = QueryResponse;

export async function fetchQueryResults({
  originId,
  organizationId,
  perPage,
  projectId,
  query,
  requestedPage,
}: FetchQueryResultsParams): Promise<FetchQueryResultsResponse> {
  const res = await http.post<FetchQueryResultsResponse>(
    `/api/v2/organizations/${organizationId}/projects/${projectId}/query?${qs.stringify(
      {
        currentPage: requestedPage ?? 0,
        perPage: perPage ?? 50,
        originId,
      },
    )}`,
    query,
  );
  return res.data;
}

export type ValidateQueryParams = {
  originId: string;
  organizationId: string;
  projectId: string;
  query: QueryRequest;
};
export type ValidateQueryResponse = QueryResponse;

export async function validateQuery(validateParams: ValidateQueryParams) {
  return fetchQueryResults({
    ...validateParams,
    requestedPage: 0,
    perPage: 50,
  });
}

export interface FetchQueryColumnOptionsParams {
  columnOriginId: string;
  organizationId: string;
  projectId: string;
  queryOriginId: string;
}

export type FetchQueryColumnOptionsResponse = QueryColumnOptionResponse[];

export async function fetchQueryColumnOptions({
  columnOriginId,
  organizationId,
  projectId,
  queryOriginId,
}: FetchQueryColumnOptionsParams): Promise<FetchQueryColumnOptionsResponse> {
  const res = await http.get<FetchQueryColumnOptionsResponse>(
    `/api/v2/organizations/${organizationId}/projects/${projectId}/query/column-options?${qs.stringify(
      {
        columnOriginId,
        queryOriginId,
      },
    )}`,
  );
  return res.data;
}
